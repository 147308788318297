<div class="slot-card" (click)="onClickByCard()">
    <nz-card nzHoverable style="width:240px" [nzCover]="initiatorCardTemplate">
        <nz-card-meta
            [nzTitle]="title"
            [nzDescription]="targetDescription"
        >
        </nz-card-meta>
        <div nz-row nzJustify="center">
          <nz-switch
            [ngModel]="realOrFakeSelected"
            [nzCheckedChildren]="checkedTemplate"
            [nzUnCheckedChildren]="unCheckedTemplate"
            (ngModelChange)="onSelectTypeChange($event)"
          ></nz-switch>
          <ng-template #checkedTemplate><span>Real person</span></ng-template>
          <ng-template #unCheckedTemplate><span>Fake person</span></ng-template>
        </div>
        <div>
            <div style="text-align: center"><b>{{ selectType === 'real' ? 'Find existing fighter' : 'Find existing fake user' }}</b></div>
            <div class="example-input">
                <ng-container *ngIf="selectType === 'real'">
                  <input
                    #fightersSearchInput
                    nz-input
                    [placeholder]="'Search ' + slotType"
                    (input)="onFightersSearch$.next(fightersSearchInput.value)"
                    [nzAutocomplete]="auto"
                  />
                  <nz-autocomplete #auto (selectionChange)="onSelectFighter($event)">
                    <nz-auto-option
                      *ngFor="let fighter of foundFighters"
                      [nzValue]="fighter"
                      nzLabel="{{ fighter.fullName }}"
                    >
                      {{ fighter.fullName }}
                    </nz-auto-option>
                  </nz-autocomplete>
                </ng-container>
                <ng-container *ngIf="selectType === 'fake'">
                <input
                  #fakeUsersSearchInput
                  nz-input
                  [placeholder]="'Search ' + slotType"
                  (input)="onFakeUsersSearch$.next(fakeUsersSearchInput.value)"
                  [nzAutocomplete]="auto"
                />
                <nz-autocomplete #auto (selectionChange)="onSelectFakeUser($event)">
                  <nz-auto-option
                    *ngFor="let fakeUser of foundFakeUsers"
                    [nzValue]="fakeUser"
                    nzLabel="{{fakeUser.lastName}} {{fakeUser.firstName}}"
                  >
                    {{fakeUser.lastName}} {{fakeUser.firstName}}
                  </nz-auto-option>
                </nz-autocomplete>
              </ng-container>
            </div>
        </div>
    </nz-card>
    <ng-template #initiatorCardTemplate>
        <img loading="lazy" alt="example" [src]="previewImage" />
    </ng-template>
    <ng-template #targetDescription>
        <div *ngIf="fighter?.details" class="description">
            <div>Weight: {{ fighter?.details?.weight || 0 }}</div>
            <div class="rating">
                <span>Rating:</span>
                <nz-input-number
                    [ngModel]="rating"
                    [nzMin]="1"
                    [nzStep]="1"
                    [nzDisabled]="!slot.hasFight()"
                    (ngModelChange)="onRatingChange($event)"
                ></nz-input-number>
            </div>
        </div>
    </ng-template>
    <div class="winner" *ngIf="isWinner">
        <img loading="lazy" nz-tooltip nzTooltipTitle="Winner" src="assets/images/winner.png" />
    </div>
    <div class="draw" *ngIf="isDraw">
        <img loading="lazy" nz-tooltip nzTooltipTitle="Draw" src="assets/images/draw.png" />
    </div>
</div>
