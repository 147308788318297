<div class="fight-slot-card">
  <nz-switch
    [ngModel]="true"
    [nzCheckedChildren]="checkedTemplate"
    [nzUnCheckedChildren]="unCheckedTemplate"
    (ngModelChange)="onSelectTypeChange($event)"
  ></nz-switch>
  <ng-template #checkedTemplate><span>Real person</span></ng-template>
  <ng-template #unCheckedTemplate><span>Fake person</span></ng-template>

  <div *ngIf="selectType === 'real'">
    <nz-form-item>
      <nz-form-control nzErrorTip="Please input name">
        <input
          class="input-search-fighter"
          placeholder="Search..."
          #fightersSearchInput
          nz-input
          (input)="onFightersSearch$.next(fightersSearchInput.value)"
          [nzAutocomplete]="auto"
        />
        <nz-autocomplete #auto (selectionChange)="onSelectUser($event)">
          <nz-auto-option
            *ngFor="let user of foundUsers"
            [nzValue]="user"
            nzLabel="{{user.lastName}} {{user.firstName}}"
          >
            {{user.lastName}} {{user.firstName}} - ({{user.role}})
          </nz-auto-option>
        </nz-autocomplete>
      </nz-form-control>
    </nz-form-item>
    <div>
      <img
        nz-image
        [nzSrc]="imagePreviewUrl"
        alt=""
      />
    </div>
  </div>
  <div *ngIf="selectType === 'fake'" [formGroup]="form">
    <nz-form-item>
      <nz-form-control>
        <input
          class="input-search-fake-user"
          placeholder="Search..."
          #fakeUsersSearchInput
          nz-input
          (input)="onFakeUsersSearch$.next(fakeUsersSearchInput.value)"
          [nzAutocomplete]="auto"
        />
        <nz-autocomplete #auto (selectionChange)="onSelectFakeUser($event)">
          <nz-auto-option
            *ngFor="let fakeUser of foundFakeUsers"
            [nzValue]="fakeUser"
            nzLabel="{{fakeUser.lastName}} {{fakeUser.firstName}}"
          >
            {{fakeUser.lastName}} {{fakeUser.firstName}}
          </nz-auto-option>
        </nz-autocomplete>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control nzErrorTip="Please input first name">
        <input nz-input placeholder="First name" type="text" formControlName="firstName" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control nzErrorTip="Please input last name">
        <input nz-input placeholder="Last name" type="text" formControlName="lastName" />
      </nz-form-control>
    </nz-form-item>
    <nz-card [nzActions]="[actionUpload]">
      <div>
        <img loading="lazy" class="image" nz-image [nzSrc]="imagePreviewUrl" />
        <app-image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [imageType]="imageType.FULL_FACE_AVATAR"
          (onUpload)="onPhotoUpload($event)"
          (onClose)="onCloseCropper()"
          [inputFile]="fileSelect"
          [isModalVisible]="!!(isModalVisible$ | async)"
        >
        </app-image-cropper>
        <input style="display: none" #fileSelect type="file" (change)="edit($event)" accept=".jpg, .jpeg, .png" />
      </div>
    </nz-card>
    <ng-template #actionUpload>
      <button nz-button nzType="dashed" (click)="fileSelect.click()">
        <i nz-icon nzType="upload"></i> Upload
      </button>
    </ng-template>
  </div>
</div>
