<div class="fight-card">
  <div class="fight-card-title">
    <h6>{{number}} Fight</h6>
    <div>
      <i class="delete-icon" (click)="onDeleteCardClick()" nz-tooltip nzTooltipTitle="Delete slot" nz-icon nzType="delete" nzTheme="twotone" nzTwotoneColor="#f93154"></i>
    </div>
  </div>
  <nz-row>
    <nz-col nzFlex="1">
      <app-events-fight-slot-card
        [position]="'left'"
        [slotNumber]="number"
        [parentForm]="form"
      ></app-events-fight-slot-card>
    </nz-col>
    <nz-col nzFlex="1">
      <app-events-fight-slot-card
        [position]="'right'"
        [slotNumber]="number"
        [parentForm]="form"
      ></app-events-fight-slot-card>
    </nz-col>
  </nz-row>
</div>
