<div>
    <nz-select
        *ngIf="timeSlot"
        (ngModelChange)="changeWinner($event)"
        nzPlaceHolder="Select winner"
        [(ngModel)]="result.winner"
    >
        <nz-option *ngIf="timeSlot.fight!.initiator"
            nzLabel="{{ timeSlot.fight!.initiator!.firstName + ' ' + timeSlot.fight!.initiator!.lastName }}"
            [nzValue]="EFightWinner.INITIATOR"
        >
        </nz-option>
        <nz-option *ngIf="timeSlot.fight!.target"
            nzLabel="{{ timeSlot.fight!.target!.firstName + ' ' + timeSlot.fight!.target!.lastName }}"
            [nzValue]="EFightWinner.TARGET"
        >
        </nz-option>
      <nz-option *ngIf="timeSlot.fight!.fakeInitiator"
                 nzLabel="{{ timeSlot.fight!.fakeInitiator!.firstName + ' ' + timeSlot.fight!.fakeInitiator!.lastName }}"
                 [nzValue]="EFightWinner.INITIATOR"
      >
      </nz-option>
      <nz-option *ngIf="timeSlot.fight!.fakeTarget"
                 nzLabel="{{ timeSlot.fight!.fakeTarget!.firstName + ' ' + timeSlot.fight!.fakeTarget!.lastName }}"
                 [nzValue]="EFightWinner.TARGET"
      >
      </nz-option>
        <nz-option
          *ngIf="timeSlot.fight!.initiator && timeSlot.fight!.target"
          nzLabel="Draw"
          [nzValue]="EFightWinner.BOTH"
        ></nz-option>
    </nz-select>
    <nz-select
        *ngIf="result.winner"
        (ngModelChange)="changeResult($event)"
        nzPlaceHolder="Select result"
        style="margin-top: 0.2rem"
        [(ngModel)]="result.result"
    >
        <nz-option *ngFor="let key of resultTypes" nzLabel="{{ key }}" [nzValue]="key"></nz-option>
    </nz-select>
</div>
