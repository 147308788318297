<div [formGroup]="form">
  <nz-card [nzActions]="[actionUpload]">
    <div class="image-block">
      <img loading="lazy" class="image" nz-image [nzSrc]="imagePreviewUrl" />
      <app-image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [imageType]="imageType.FULL_FACE_AVATAR"
        (onUpload)="onPhotoUpload($event)"
        (onClose)="onCloseCropper()"
        [inputFile]="fileSelect"
        [isModalVisible]="!!(isModalVisible$ | async)"
      >
      </app-image-cropper>
      <input style="display: none" #fileSelect type="file" (change)="edit($event)" accept=".jpg, .jpeg, .png" />
    </div>
  </nz-card>
  <ng-template #actionUpload>
    <button nz-button nzType="dashed" (click)="fileSelect.click()">
      <i nz-icon nzType="upload"></i> Upload
    </button>
  </ng-template>
  <nz-form-item>
    <nz-form-control nzErrorTip="Please input first name">
      <input nz-input placeholder="First name" type="text" formControlName="firstName" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control nzErrorTip="Please input last name">
      <input nz-input placeholder="Last name" type="text" formControlName="lastName" />
    </nz-form-control>
  </nz-form-item>

</div>
