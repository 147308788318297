import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { RewardUserApiParams } from '@fca-app/api/fca/payments/interfaces/params/reward-user-api.params';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaPaymentsApiService extends FcaApiServiceBase {
    rewardUser(params: RewardUserApiParams): Observable<void> {
        const url = [this.apiUrl, 'coins-payments-admin', 'reward-user'].join('/');

        return this.http
            .post<void>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }
}
