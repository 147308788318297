import { Component } from '@angular/core';
import { UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';

@UntilDestroy()
@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
})
export class EventsComponent {}
