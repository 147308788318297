import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChatApiService } from '@fca-app/api/chat/chat-api.service';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { ArenaEventCreateFakeUserComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/arena-event-create-fake-user/arena-event-create-fake-user.component';
import { ArenaEventManagePriceComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/arena-event-manage-price/arena-event-manage-price.component';
import { SlotType } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/enums/slot-type.enum';
import { ResultsModalComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/results-modal/results-modal.component';
import { VideoViewerComponent } from '@fca-app/dashboard/components/home/arena-list/arena-details/video-viewer/video-viewer.component';
import { EventStatus } from '@fca-app/enums/event-status.enum';
import { ArenaModel } from '@fca-app/models/users/arena/arena.model';
import { FightEventModel } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import { FightTimeSlotModel } from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.model';
import { FightModel } from '@fca-app/models/users/arena/fight-event/fight/fight.model';
import { FighterModel } from '@fca-app/models/users/fighter/fighter.model';
import { ArenasService } from '@fca-app/services/arenas.service';
import { FightEventsService } from '@fca-app/services/fight-events.service';
import { FightsService } from '@fca-app/services/fights.service';
import { EAdminPermissions } from '@fca-app/shared/enums/admin-permissions.enum';
import { fileToBase64 } from '@fca-app/shared/helpers/file-to-base64.helper';
import { UploadedCropperImage } from '@fca-app/shared/interfaces/uploaded-cropper-image.interface';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import * as moment from 'moment-timezone';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, switchMap, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-arena-event',
    styleUrls: ['./arena-event.component.scss'],
    templateUrl: './arena-event.component.html',
})
export class ArenaEventComponent implements OnInit {
    readonly slotTypes = SlotType;

    @Input() fightEvent: FightEventModel;
    @Input() arena: ArenaModel;
    @Input() previewPlaceholder: string;

    @ViewChild(VideoViewerComponent) videoComponent: VideoViewerComponent;

    fromDate = new Date();
    onSwapSlots$ = new Subject<void>();
    imageChangedEvent: Event;
    isModalVisible$ = new BehaviorSubject<boolean>(false);
    permissions = EAdminPermissions;

    constructor(
        private readonly fightsService: FightsService,
        private readonly fightEventsService: FightEventsService,
        private readonly appConfigService: AppMainConfigService,
        private readonly arenasService: ArenasService,
        private readonly nzNotificationService: NzNotificationService,
        private readonly nzImageService: NzImageService,
        private readonly nzModalService: NzModalService,
        public readonly preloadService: FcaPreloadService,
        private readonly chatApiService: ChatApiService
    ) {}

    ngOnInit(): void {
        this.previewPlaceholder = this.previewPlaceholder || this.appConfigService.getConfig().previewImage;
        this.fromDate = new Date(
            moment(this.fightEvent.fromDate)
                .tz(this.fightEvent.location?.tz ?? 'UTC')
                .format('l LT')
        );
        this.onSwapSlots$
            .pipe(
                tap(() => this.preloadService.preload(true)),
                switchMap(() => {
                    return this.arenasService
                        .updateTimeSlots(this.arena, this.fightEvent)
                        .pipe(tap(() => this.preloadService.preload(false)));
                }),
                untilDestroy(this)
            )
            .subscribe();
    }

    get countFights(): number {
        return this.fightEvent.fightSlots.filter(fe => Boolean(fe.fight)).length;
    }

    onSubmitPPV(): void {
        // if (this.ppvValidateForm.valid) {
        //     this.fightEventsService
        //         .updatePpv(
        //             this.fightEvent.id,
        //             this.ppvValidateForm.value.price,
        //             this.ppvValidateForm.value.discountPrice,
        //             this.ppvValidateForm.value.sale
        //         )
        //         .pipe(untilDestroy(this), take(1))
        //         .subscribe(() => {
        //             this.fightEvent.isPPV = true;
        //             // this.fightEvent.sale = this.ppvValidateForm.value.sale;
        //             // this.fightEvent.pricePPV = this.ppvValidateForm.value.price;
        //             // this.fightEvent.priceDiscountPPV = this.ppvValidateForm.value.discountPrice;
        //             this.nzNotificationService
        //                 .success('Success', '', { nzDuration: 1500 })
        //                 .onClick.pipe(take(1), untilDestroy(this))
        //                 .subscribe();
        //         });
        // }
    }

    onSetCurrentlyFightClick(slot: FightTimeSlotModel): void {
        this.chatApiService
            .setActiveSlot(slot.id)
            .pipe(take(1), untilDestroy(this))
            .subscribe();
    }

    parseFightInputTime(value: string): { min: number; sec: number } | null {
        if (!value) {
            return null;
        }

        const [min, sec] = value.split(':');
        return {
            min: Number(min),
            sec: Number(sec),
        };
    }

    onSaveFightSlotTime(slot: FightTimeSlotModel, inputFrom: HTMLInputElement, inputTo: HTMLInputElement): void {
        const timeFrom = this.parseFightInputTime(inputFrom.value);
        const timeTo = this.parseFightInputTime(inputTo.value);
        const fightId = slot.fight?.id;

        if (timeFrom && timeTo && fightId) {
            const videoData = this.videoComponent.getVideoData();

            const timestamp = videoData.timestampFirstFragment + (timeFrom.min * 60 + timeFrom.sec) * 1000;
            const duration = timeTo.min * 60 + timeTo.sec - (timeFrom.min * 60 + timeTo.sec);
            const linkPart1 = this.eventVideoSrc()!.slice(0, this.eventVideoSrc()!.indexOf('playlist'));
            const linkPart2 = `playlist_dvr_range-${Math.floor(timestamp / 1000)}-${duration}.m3u8`;
            const link = linkPart1.concat(linkPart2);
            this.fightsService
                .updateFight(fightId, this.fightEvent.location?.tz, link)
                .pipe(take(1), untilDestroy(this))
                .subscribe(fight => {
                    slot.fight!.fightLink = fight.fightLink;
                });
        }
    }

    setTimeToInput(input: HTMLInputElement): void {
        const data = this.videoComponent.getVideoData();
        const time = Math.floor(data.currentTime);
        let mins: string | number = Math.floor(time / 60);
        mins = mins < 10 ? `0${mins}` : mins;
        let sec: string | number = time % 60;
        sec = sec < 10 ? `0${sec}` : sec;
        input.value = `${mins}:${sec}`;
    }

    onDeleteSlotVideo(slot: FightTimeSlotModel): void {
        const fightId = slot.fight?.id;

        if (fightId) {
            this.nzModalService.confirm({
                nzTitle: 'Confirm video removal',
                nzOkDanger: true,
                nzOkText: 'Confirm',
                nzOnOk: () => {
                    this.preloadService.preload(true);
                    this.fightsService
                        .updateFight(fightId, this.fightEvent.location?.tz, null)
                        .pipe(
                            tap(() => {
                                this.preloadService.preload(false);
                                slot.fight!.fightLink = null;
                            }),
                            take(1),
                            untilDestroy(this)
                        )
                        .subscribe();
                },
            });
        }
    }

    onCreateEmptySlotClick(): void {
        this.arenasService
            .createEmptySlot(this.fightEvent.id, this.fightEvent.location?.tz || '')
            .pipe(untilDestroy(this), take(1))
            .subscribe(createdSlot => {
                this.fightEvent.fightSlots.unshift(createdSlot);
            });
    }

    onFromDateChange(value: string | null): void {
        if (value) {
            this.fightEvent.fromDate = moment.utc(moment(value).tz(this.fightEvent.location!.tz, true));
        }
    }

    eventHasVideo(): boolean {
        return (
            String(this.fightEvent.video?.streamUrl).endsWith('.m3u8') ||
            String(this.fightEvent.video?.videoUrl).endsWith('.m3u8')
        );
    }

    eventVideoSrc(): string | null {
        return this.fightEvent.status === EventStatus.Ongoing
            ? this.fightEvent.video!.streamUrl
            : this.fightEvent.video!.videoUrl;
    }

    openResultsModal(timeSlot: FightTimeSlotModel): void {
        this.nzModalService.create({
            nzTitle: 'Setting results',
            nzContent: ResultsModalComponent,
            nzOkText: 'Save',
            nzComponentParams: {
                timeSlot,
            },
            nzOkDisabled: true,
        });
    }

    onMainCardChange(checked: boolean, slot: FightTimeSlotModel): void {
        slot.isMainCard = checked;
        this.arenasService
            .updateTimeSlots(this.arena, this.fightEvent)
            .pipe(take(1), untilDestroy(this))
            .subscribe();
    }

    onAddNewCardToSlot(type: SlotType, slot: FightTimeSlotModel): void {
        slot.fight = slot.fight || new FightModel();

        if (type === SlotType.Target) {
            slot.fight.target = new FighterModel();
        } else if (type === SlotType.Initiator) {
            slot.fight.initiator = new FighterModel();
        }
    }

    onCloseCropper(): void {
        this.isModalVisible$.next(false);
    }

    onOpenModalToManageEventPrice(): void {
        this.nzModalService.create({
            nzTitle: 'Manage price',
            nzContent: ArenaEventManagePriceComponent,
            nzOkText: 'Save',
            nzFooter: null,
            nzComponentParams: { fightEventId: this.fightEvent.id },
        });
    }

    onOpenModalToCreateFakeUser(): void {
        this.nzModalService.create({
            nzTitle: 'New fake user',
            nzContent: ArenaEventCreateFakeUserComponent,
            nzOkText: 'Save',
            nzComponentParams: {},
        });
    }

    async onPhotoUpload(uploadedImage: UploadedCropperImage) {
        this.isModalVisible$.next(false);
        this.previewPlaceholder = String(await fileToBase64(uploadedImage.file));
        (this.imageChangedEvent.target as HTMLInputElement).value = '';
        this.preloadService.preload(true);
        this.arenasService
            .uploadFightEventPhoto(this.fightEvent.id, 0, uploadedImage.file)
            .pipe(
                take(1),
                finalize(() => {
                    this.preloadService.preload(false);
                    this.isModalVisible$.next(false);
                }),
                untilDestroy(this)
            )
            .subscribe(imageModel => {
                this.fightEvent.image = imageModel;
                this.nzNotificationService
                    .success('Success', '', { nzDuration: 1500 })
                    .onClick.pipe(take(1), untilDestroy(this))
                    .subscribe();
            });
    }

    onZoomPhotoClick(): void {
        this.nzImageService.preview([{ src: this.previewPlaceholder, alt: 'Preview' }], { nzZoom: 0.7, nzRotate: 0 });
    }

    onDeletePhotoClick(): void {}

    onPhotoChange(e: Event): void {
        this.imageChangedEvent = e;
        this.isModalVisible$.next(true);
    }

    onDeleteSlot(slot: FightTimeSlotModel, slotIdx: number): void {
        this.nzModalService.confirm({
            nzTitle: 'Confirm slot removal',
            nzOkDanger: true,
            nzOkText: 'Confirm',
            nzOnOk: () => {
                this.preloadService.preload(true);
                this.fightsService
                    .deleteFight(slot.fight!.id, slot.id)
                    .pipe(
                        tap(() => this.preloadService.preload(false)),
                        take(1),
                        untilDestroy(this)
                    )
                    .subscribe(() => {
                        this.fightEvent.fightSlots[slotIdx].fight = undefined;
                        this.fightEvent.fightSlots[slotIdx].fightId = null;
                        this.nzNotificationService
                            .success('Success', '', { nzDuration: 1500 })
                            .onClick.pipe(take(1), untilDestroy(this))
                            .subscribe();
                    });
            },
        });
    }

    swapSlots(slot: FightTimeSlotModel, slotIdx: number, direction: 'up' | 'down'): void {
        const toSlotIdx = direction === 'up' ? slotIdx - 1 : slotIdx + 1;
        const nextSlot = this.fightEvent.fightSlots[toSlotIdx];

        this.fightEvent.fightSlots[toSlotIdx] = slot;
        this.fightEvent.fightSlots[slotIdx] = nextSlot;

        const slotFromDate = slot.fromDate;
        const slotToDate = slot.toDate;
        slot.fromDate = nextSlot.fromDate;
        slot.toDate = nextSlot.toDate;
        nextSlot.fromDate = slotFromDate;
        nextSlot.toDate = slotToDate;

        this.onSwapSlots$.next();
    }
}
