import { Injectable } from '@angular/core';
import { GoogleApiService } from '@fca-app/api/geocoding/google-api.service';
import { GeocodingFactory } from '@fca-app/models/geocoding/geocoding.factory';
import { GeocodingMapper } from '@fca-app/models/geocoding/geocoding.mapper';
import { GeocodingModel } from '@fca-app/models/geocoding/geocoding.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class GeocodingService {
    constructor(private readonly geocodingService: GoogleApiService) {}

    public searchAddress(query: string): Observable<GeocodingModel[]> {
        return this.geocodingService
            .searchAddress(query)
            .pipe(
                map(resp =>
                    resp.results.map(data =>
                        new GeocodingFactory().getModelFromData(new GeocodingMapper().mapData(data))
                    )
                )
            );
    }
}
