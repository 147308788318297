import { Injectable } from '@angular/core';
import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { UserPreviewResponse } from '@fca-app/api/fca/users/interfaces/response/user-preview.response';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { UserAccountInfoFactory } from '@fca-app/models/users/user/account-info/user-account-info.factory';
import { UserAccountInfoMapper } from '@fca-app/models/users/user/account-info/user-account-info.mapper';
import { UserAccountInfoModel } from '@fca-app/models/users/user/account-info/user-account-info.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UsersService {
    constructor(private readonly usersService: FcaUsersApiService) {}

    confirmWithdrawal(userId: number): Observable<UserAccountInfoModel> {
        return this.usersService
            .confirmUserWithdrawal(userId)
            .pipe(map(raw => new UserAccountInfoFactory().getModelFromData(new UserAccountInfoMapper().mapData(raw))));
    }

    previewList(
        searchTerm: string,
        page: number,
        limit: number,
        role?: EUserRoleName
    ): Observable<UserPreviewResponse[]> {
        return this.usersService.searchUsersForPreview({ searchTerm, limit, page, role });
    }
}
