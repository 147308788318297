import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PaymentsCoinsComponent } from '@fca-app/dashboard/components/home/payments/coins/payments-coins.component';
import { PaymentsComponent } from '@fca-app/dashboard/components/home/payments/payments.component';
import { SharedModule } from '@fca-app/shared/shared.module';

@NgModule({
    declarations: [PaymentsComponent, PaymentsCoinsComponent],
    imports: [CommonModule, FormsModule, SharedModule, RouterModule, ReactiveFormsModule],
    providers: [],
    exports: [],
})
export class PaymentsModule {}
