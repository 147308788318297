import { LocationApiResponse } from '@fca-app/api/fca/users/interfaces/response/location-api.response';
import { LocationAddressFactory } from '@fca-app/models/location-address/location-address.factory';
import { LocationAddressMapper } from '@fca-app/models/location-address/location-address.mapper';
import { LocationModelData } from '@fca-app/models/location/location.model';

export class LocationMapper {
    mapData(raw: LocationApiResponse): LocationModelData {
        try {
            return {
                id: raw.id,
                name: raw.name,
                address: new LocationAddressFactory().getModelFromData(
                    new LocationAddressMapper().mapData(raw.address)
                ),
                link: raw.link || '',
                description: raw.description || '',
                isPending: raw.isPending,
                tz: raw.timeZone,
            };
        } catch (err) {
            // console.log(err);
            return {} as LocationModelData;
        }
        // return {
        //     id: raw.id,
        //     name: raw.name,
        //     address: new LocationAddressFactory().getModelFromData(new LocationAddressMapper().mapData(raw.address)),
        //     link: raw.link || '',
        //     description: raw.description || '',
        //     isPending: raw.isPending,
        //     tz: raw.timeZone,
        // };
    }
}
