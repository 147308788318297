import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CreateEventComponent } from '@fca-app/dashboard/components/events/create/create-event.component';
import { FightSlotCardComponent } from '@fca-app/dashboard/components/events/create/fight-slot/fight-slot-card/fight-slot-card.component';
import { FightSlotComponent } from '@fca-app/dashboard/components/events/create/fight-slot/fight-slot.component';
import { EventsComponent } from '@fca-app/dashboard/components/events/events.component';
import { SharedModule } from '@fca-app/shared/shared.module';

@NgModule({
    declarations: [EventsComponent, CreateEventComponent, FightSlotComponent, FightSlotCardComponent],
    imports: [CommonModule, RouterModule, SharedModule, ReactiveFormsModule],
    providers: [],
})
export class EventsModule {}
