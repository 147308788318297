import { FightApiResponse } from '@fca-app/api/fca/fights/interfaces/response/fight-api.response';
import { FightTimeSlotFactory } from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.factory';
import { FightTimeSlotMapper } from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.mapper';
import { FightFactory } from '@fca-app/models/users/arena/fight-event/fight/fight.factory';
import { FightModelData } from '@fca-app/models/users/arena/fight-event/fight/fight.model';
import { FakeUserFactory } from '@fca-app/models/users/fake-user/fake-user.factory';
import { FakeUserMapper } from '@fca-app/models/users/fake-user/fake-user.mapper';
import { FighterFactory } from '@fca-app/models/users/fighter/fighter.factory';
import { FighterMapper } from '@fca-app/models/users/fighter/fighter.mapper';

export class FightMapper {
    mapData(raw: FightApiResponse, timezone: string): FightModelData {
        return {
            id: raw.id,
            status: raw.status,
            winner: raw.winner,
            ...(raw.initiator && {
                initiator: new FighterFactory().getModelFromData(new FighterMapper().mapData(raw.initiator)),
            }),
            ...(raw.target && {
                target: new FighterFactory().getModelFromData(new FighterMapper().mapData(raw.target)),
            }),
            ...(raw.fakeTarget && {
                fakeTarget: new FakeUserFactory().getModelFromData(new FakeUserMapper().mapData(raw.fakeTarget)),
            }),
            ...(raw.fakeInitiator && {
                fakeInitiator: new FakeUserFactory().getModelFromData(new FakeUserMapper().mapData(raw.fakeInitiator)),
            }),
            slot:
                raw.slot &&
                new FightTimeSlotFactory().getModelFromData(new FightTimeSlotMapper().mapData(raw.slot, timezone)),
            fightLink: raw.fightLink,
        };
    }
}
