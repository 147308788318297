import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FightEventsService } from '@fca-app/services/fight-events.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'arena-event-manage-price',
    styleUrls: ['./arena-event-manage-price.component.scss'],
    templateUrl: './arena-event-manage-price.component.html',
})
export class ArenaEventManagePriceComponent implements AfterViewInit, OnInit {
    @Input() fightEventId: string;
    managePriceForm: FormGroup;
    isFreeForSubscribers: boolean;

    constructor(
        private readonly modal: NzModalRef,
        private readonly fightEventsService: FightEventsService,
        private readonly nzNotificationService: NzNotificationService
    ) {
        this.managePriceForm = new FormGroup({
            price: new FormControl(0, [Validators.required, Validators.min(1)]),
            discount: new FormControl(null, []),
            sale: new FormControl(null, []),
        });
        this.managePriceForm.statusChanges.pipe(untilDestroy(this)).subscribe(() => {
            this.modal.updateConfig({ nzOkDisabled: !this.managePriceForm.valid });
        });
    }

    onFreeForSubscribersClick(): void {
        this.fightEventsService
            .setFreeForSubscribers(this.fightEventId, this.isFreeForSubscribers)
            .pipe(untilDestroy(this), take(1))
            .subscribe(() => {
                this.nzNotificationService
                    .success(
                        'Success',
                        this.isFreeForSubscribers
                            ? 'The event is free for subscribers'
                            : 'The event is not free for subscribers',
                        { nzDuration: 2500 }
                    )
                    .onClick.pipe(take(1), untilDestroy(this))
                    .subscribe();
            });
    }

    onSavePriceClick(): void {
        if (this.managePriceForm.valid) {
            const { price, discount, sale } = this.managePriceForm.value;
            this.fightEventsService
                .updatePrice(this.fightEventId, price, discount, sale)
                .pipe(untilDestroy(this), take(1))
                .subscribe(() => {
                    this.nzNotificationService
                        .success('The price has been updated', '', { nzDuration: 2500 })
                        .onClick.pipe(take(1), untilDestroy(this))
                        .subscribe();
                });
        }
    }

    ngOnInit(): void {
        this.fightEventsService
            .getPriceInfo(this.fightEventId)
            .pipe(take(1))
            .subscribe(priceInfo => {
                this.isFreeForSubscribers = priceInfo.isFreeForSubscribers;
                this.managePriceForm.patchValue({
                    price: priceInfo.price?.price || 0,
                    discount: priceInfo.price?.discount || null,
                    sale: priceInfo.price?.sale || null,
                });
            });
    }

    ngAfterViewInit(): void {
        this.modal.updateConfig({ nzOkDisabled: true });
    }
}
