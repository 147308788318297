<nz-tabset>
  <nz-tab class="rewards" nzTitle="Rewards">
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input #inputSearchUser
             type="text"
             nz-input
             placeholder="Search user..."
             [nzAutocomplete]="auto"
             (input)="onSearchUser$.next(inputSearchUser.value)"
      />
      <nz-autocomplete #auto (selectionChange)="onSelectUser($event)">
        <nz-auto-option
          *ngFor="let user of foundUsers"
          [nzValue]="user"
          [nzLabel]="getPreviewName(user)"
        >
          <b>{{user.email || ''}}</b> {{getPreviewName(user)}}
        </nz-auto-option>
      </nz-autocomplete>
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <span nz-icon nzType="search"></span>
    </ng-template>

    <div class="form" nz-row [formGroup]="form">
      <nz-form-item nz-col nzSpan="24">
        <nz-form-control nzErrorTip="Please input correct coins value (Max: 1000)">
          <nz-input-group [nzSuffix]="suffixTemplateInfo" [nzPrefix]="prefixTemplateUser">
            <input type="number" formControlName="coins" nz-input placeholder="Count coins" />
          </nz-input-group>
          <ng-template #prefixTemplateUser><span nz-icon nzType="dollar-circle"></span></ng-template>
          <ng-template #suffixTemplateInfo>
            <span nz-icon nz-tooltip nzTooltipTitle="Max 1000" nzType="info-circle"></span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-col nzSpan="24">
        <nz-form-control nzErrorTip="Please input correct coins value (Max: 1000)">
          <nz-select formControlName="rewardType">
            <nz-option nzValue="bonus" nzLabel="Bonus"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <button nz-button nzType="primary" nzBlock [disabled]="!form.valid || rewarded" (click)="onRewardClick()">Reward</button>
    </div>
  </nz-tab>
</nz-tabset>
