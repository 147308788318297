import { Injectable } from '@angular/core';
import { FcaPaymentsApiService } from '@fca-app/api/fca/payments/fca-payments-api.service';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentsService {
    constructor(private readonly paymentsApiService: FcaPaymentsApiService) {}

    rewardUser(targetId: number, amount: number, rewardType: string): Observable<void> {
        return this.paymentsApiService.rewardUser({ targetId, amount, rewardType });
    }
}
