import { Injectable } from '@angular/core';
import { FcaImagesApiService } from '@fca-app/api/fca/images/fca-images-api.service';
import { UploadPhotoParams } from '@fca-app/api/fca/images/interfaces/params/upload-photo.params';
import { UserImageFactory } from '@fca-app/models/images/user-image/user-image.factory';
import { UserImageMapper } from '@fca-app/models/images/user-image/user-image.mapper';
import { UserImageModel } from '@fca-app/models/images/user-image/user-image.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  UploadFakeUserImageResponse
} from "@fca-app/api/fca/images/interfaces/response/upload-fake-user-image.response";

@Injectable()
export class ImagesService {
    constructor(private readonly imagesService: FcaImagesApiService) {}

    uploadFakeUserImage(file: File): Observable<UploadFakeUserImageResponse> {
        return this.imagesService.uploadFakeUserImage(file);
    }

    uploadImage(userId: number, params: UploadPhotoParams): Observable<UserImageModel[]> {
        return this.imagesService
            .uploadPhoto(userId, params)
            .pipe(
                map(uploadedImages =>
                    uploadedImages.map(img =>
                        new UserImageFactory().getModelFromData(new UserImageMapper().mapData(img))
                    )
                )
            );
    }

    deleteImagesByIds(userId: number, imagesIds: number[]) {
        return this.imagesService.deleteImages(userId, imagesIds);
    }
}
