<div>
    <h3 nz-typography>Contact person</h3>
    <form nz-form>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="first_name">First name:</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input
                    nz-input
                    [(ngModel)]="data.firstName"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onChange()"
                    id="first_name"
                    name="first_name"
                    type="text"
                    placeholder="First name"
                    required
                />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="last_name">Last name:</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input
                    nz-input
                    [(ngModel)]="data.lastName"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onChange()"
                    id="last_name"
                    name="last_name"
                    type="text"
                    placeholder="Last name"
                    required
                />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="email">Email:</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input
                    nz-input
                    [(ngModel)]="data.email"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onChange()"
                    name="email"
                    type="email"
                    placeholder="mail@example.com"
                    required
                />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="phoneNumber">Phone Number:</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input
                    nz-input
                    [(ngModel)]="data.phoneNumber"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onChange()"
                    id="phoneNumber"
                    name="phoneNumber"
                    type="phone"
                    placeholder="Phone Number"
                    required
                />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="description">Create date:</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input nz-input disabled [ngModel]="data.createDate | date: 'medium'" type="text" [ngModelOptions]="{standalone: true}" />
            </nz-form-control>
        </nz-form-item>
    </form>
</div>
