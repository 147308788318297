export interface FakeUserModelData {
    id: string;
    firstName: string;
    lastName: string;
    image: string | null;
}

export interface FakeUserModel extends FakeUserModelData {}

export class FakeUserModel {}
