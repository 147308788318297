import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { GetDataApiResponse } from '@fca-app/api/fca/shared/interfaces/get-data-response.interface';
import { AddPenaltyParams } from '@fca-app/api/fca/users/interfaces/params/add-penalty.params';
import { CreateFanParams } from '@fca-app/api/fca/users/interfaces/params/create/create-fan.params';
import { CreateFighterParams } from '@fca-app/api/fca/users/interfaces/params/create/create-fighter.params';
import { CreateLocationProviderParams } from '@fca-app/api/fca/users/interfaces/params/create/create-location-provider.params';
import {
    SearchUsersForPreviewParams,
    SearchUsersParams,
} from '@fca-app/api/fca/users/interfaces/params/search-users.params';
import { UpdateAdminParams } from '@fca-app/api/fca/users/interfaces/params/update/update-admin.params';
import { UpdateFighterParams } from '@fca-app/api/fca/users/interfaces/params/update/update-fighter.params';
import { FakeUserApiResponse } from '@fca-app/api/fca/users/interfaces/response/fake-user/fake-user-api.response';
import { FanApiResponse } from '@fca-app/api/fca/users/interfaces/response/fan-api.response';
import { FighterAccountInfoResponse } from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-account-info.response';
import { FighterApiResponse } from '@fca-app/api/fca/users/interfaces/response/fighter/fighter-api.response';
import { LocationProviderApiResponse } from '@fca-app/api/fca/users/interfaces/response/location-provider-api.response';
import { AdminApiResponse, UserApiResponse } from '@fca-app/api/fca/users/interfaces/response/user-api.response';
import { UserPreviewResponse } from '@fca-app/api/fca/users/interfaces/response/user-preview.response';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaUsersApiService extends FcaApiServiceBase {
    createFakeUser(params: { firstName: string; lastName: string; image?: string }): Observable<FakeUserApiResponse> {
        const url = [this.apiUrl, 'users', 'admin', 'fake-user'].join('/');

        return this.http
            .post<FakeUserApiResponse>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    searchFakeUsers(searchTerm: string): Observable<FakeUserApiResponse[]> {
        const url = [this.apiUrl, 'users', 'admin', 'search-fake-users'].join('/');
        const httpParams: HttpParams = new HttpParams().set('searchTerm', searchTerm);

        return this.http
            .get<FakeUserApiResponse[]>(url, { headers: this.defaultHeaders, params: httpParams })
            .pipe(publishReplay(1), refCount());
    }

    searchUsersForPreview(params: SearchUsersForPreviewParams): Observable<UserPreviewResponse[]> {
        const url = [this.apiUrl, 'users', 'admin', 'preview-list'].join('/');
        let httpParams: HttpParams = new HttpParams()
            .set('searchTerm', params.searchTerm)
            .set('page', params.page - 1)
            .set('limit', params.limit);

        if (params.role) {
            httpParams = httpParams.set('role', params.role);
        }

        return this.http
            .get<UserPreviewResponse[]>(url, { headers: this.defaultHeaders, params: httpParams })
            .pipe(publishReplay(1), refCount());
    }

    search<D>(queryParams: SearchUsersParams): Observable<GetDataApiResponse<D>> {
        const url = [this.apiUrl, 'admin', 'users'].join('/');
        let httpParams: HttpParams = new HttpParams()
            .set('limit', queryParams.limit)
            .set('page', queryParams.page - 1)
            .set('roleName', queryParams.roleName);

        if (queryParams.searchTerm) {
            httpParams = httpParams.set('searchTerm', queryParams.searchTerm);
        }

        return this.http
            .get<GetDataApiResponse<D>>(url, { headers: this.defaultHeaders, params: httpParams })
            .pipe(publishReplay(1), refCount());
    }

    createFighter(params: CreateFighterParams): Observable<FighterApiResponse> {
        const url = [this.apiUrl, 'auth', 'admin', 'create-fighter'].join('/');

        return this.http
            .post<FighterApiResponse>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    createFan(params: CreateFanParams): Observable<FanApiResponse> {
        const url = [this.apiUrl, 'auth', 'admin', 'create-fan'].join('/');

        return this.http
            .post<FanApiResponse>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    createLocationProvider(params: CreateLocationProviderParams): Observable<LocationProviderApiResponse> {
        const url = [this.apiUrl, 'auth', 'admin', 'create-location-provider'].join('/');

        return this.http
            .post<LocationProviderApiResponse>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    updateFighter(params: UpdateFighterParams): Observable<FighterApiResponse> {
        const url = [this.apiUrl, 'users', 'admin', 'update-fighter'].join('/');

        return this.http
            .patch<FighterApiResponse>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    getProfileInfo(userId: number): Observable<UserApiResponse> {
        const url = [this.apiUrl, 'users', 'admin', 'profile', userId].join('/');

        return this.http
            .get<UserApiResponse>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    deletePenalty(id: number): Observable<void> {
        const url = [this.apiUrl, 'user-penalties', id].join('/');

        return this.http
            .delete<void>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    addPenalty(params: AddPenaltyParams): Observable<void> {
        const url = [this.apiUrl, 'user-penalties'].join('/');

        return this.http
            .post<void>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    getUserById<D>(id: number): Observable<D> {
        const url = [this.apiUrl, 'users', id].join('/');

        return this.http
            .get<D>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    getUserAccountInfo(id: number): Observable<FighterAccountInfoResponse> {
        const url = [this.apiUrl, 'users', 'admin', 'account-info', id].join('/');

        return this.http
            .get<FighterAccountInfoResponse>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    confirmUserWithdrawal(fighterId: number): Observable<FighterAccountInfoResponse> {
        const url = [this.apiUrl, 'users', 'admin', 'confirm-withdraw-request', fighterId].join('/');

        return this.http
            .post<FighterAccountInfoResponse>(url, {}, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    getAdminsList(): Observable<AdminApiResponse[]> {
        const url = [this.apiUrl, 'users', 'admin', 'admins-list'].join('/');

        return this.http
            .get<AdminApiResponse[]>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    updateAdmin(params: UpdateAdminParams): Observable<void> {
        const url = [this.apiUrl, 'users', 'admin', 'update-admin'].join('/');

        return this.http
            .put<void>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    verifyUser(userId: number): Observable<void> {
        const url = [this.apiUrl, 'users', 'admin', 'verify-user'].join('/');

        return this.http
            .post<void>(url, { userId }, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }
}
