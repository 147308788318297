import { FightEventModel } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import { FightModel } from '@fca-app/models/users/arena/fight-event/fight/fight.model';
import * as moment from 'moment-timezone';

export interface FightTimeSlotModelData {
    id: number;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    fightEvent?: FightEventModel;
    fight?: FightModel;
    locationId: number | null;
    fightId: number | null;
    isMainCard: boolean;
    fightLink: string | null;
}

export interface FightTimeSlotModel extends FightTimeSlotModelData {}

export class FightTimeSlotModel {
    hasFight(): boolean {
        return Boolean(this.fight?.id);
    }

    hasInitiator(): boolean {
        return Boolean(this.fight?.initiator) || Boolean(this.fight?.fakeInitiator);
    }

    hasTarget(): boolean {
        return Boolean(this.fight?.target) || Boolean(this.fight?.fakeTarget);
    }

    isEmptySlot(): boolean {
        return !this.hasTarget() || !this.hasInitiator();
    }

    isUnknownFightResult(): boolean {
        return Boolean(this.fight) && this.fight!.winner === null;
    }

    fromToTimeString(timezone: string): string {
        return (
            moment(this.fromDate)
                .tz(timezone)
                .format('HH:mm') +
            ' - ' +
            moment(this.toDate)
                .tz(timezone)
                .format('HH:mm')
        );
    }
}
