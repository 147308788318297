import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ArenasModule } from '@fca-app/dashboard/components/home/arena-list/arenas.module';
import { FansModule } from '@fca-app/dashboard/components/home/fan-list/fans.module';
import { FightersModule } from '@fca-app/dashboard/components/home/fighter-list/fighters.module';
import { FightEventsService } from '@fca-app/services/fight-events.service';
import { MatchmakingService } from '@fca-app/services/matchmaking.service';
import { SharedModule } from '@fca-app/shared/shared.module';
import { ContentCreatorsListComponent } from './content-creators-list/content-creators-list.component';

@NgModule({
    declarations: [ContentCreatorsListComponent],
    imports: [ArenasModule, FightersModule, FansModule, FormsModule, SharedModule, RouterModule, ReactiveFormsModule],
    providers: [FightEventsService, MatchmakingService],
})
export class DashboardHomeModule {}
