import { Injectable } from '@angular/core';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { UploadPhotoParams } from '@fca-app/api/fca/images/interfaces/params/upload-photo.params';
import { UserImageApiResponse } from '@fca-app/api/fca/images/interfaces/response/image-api.response';
import { UploadFakeUserImageResponse } from '@fca-app/api/fca/images/interfaces/response/upload-fake-user-image.response';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class FcaImagesApiService extends FcaApiServiceBase {
    uploadFakeUserImage(image: File): Observable<UploadFakeUserImageResponse> {
        const url = [this.apiUrl, 'images', 'admin', 'upload-fake-user-image'].join('/');
        const formData: FormData = new FormData();
        formData.append('file', image, image.name);

        return this.http.post<UploadFakeUserImageResponse>(url, formData).pipe(publishReplay(1), refCount());
    }

    uploadPhoto(userId: number, data: UploadPhotoParams): Observable<UserImageApiResponse[]> {
        const url = [this.apiUrl, 'images', 'admin', 'upload-image'].join('/');
        const formData: FormData = new FormData();
        formData.append('image', data.file, data.file.name);
        formData.append('imageType', data.type);
        formData.append('positionId', String(data.position));
        formData.append('userId', String(userId));

        return this.http.post<UserImageApiResponse[]>(url, formData).pipe(publishReplay(1), refCount());
    }

    deleteImages(userId: number, imagesIds: number[]) {
        const url = [this.apiUrl, 'images', 'admin', 'delete-images'].join('/');
        const options = {
            headers: this.defaultHeaders,
            body: {
                userId,
                imagesIds,
            },
        };

        return this.http.request('delete', url, options).pipe(publishReplay(1), refCount());
    }
}
