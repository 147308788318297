import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface SelectedFighterInSlotCard {
    id?: number;
    firstName: string;
    lastName: string;
    image?: string;
}

@Component({
    selector: 'app-events-fight-slot',
    templateUrl: './fight-slot.component.html',
    styleUrls: ['./fight-slot.component.scss'],
})
export class FightSlotComponent implements OnInit {
    @Input() parentForm: FormGroup;
    @Input() number: number;
    @Output() onDeleteCard = new EventEmitter<number>();

    constructor() {}

    get form(): FormGroup {
        return this.parentForm.get(String(this.number)) as FormGroup;
    }

    ngOnInit(): void {
        this.parentForm.addControl(String(this.number), new FormGroup({}));
    }

    onDeleteCardClick() {
        console.log('remove', this.number);
        this.parentForm.removeControl(String(this.number));
        this.onDeleteCard.next(this.number);
        this.onDeleteCard.complete();
    }
}
