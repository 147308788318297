import { Injectable } from '@angular/core';
import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { FakeUserFactory } from '@fca-app/models/users/fake-user/fake-user.factory';
import { FakeUserMapper } from '@fca-app/models/users/fake-user/fake-user.mapper';
import { FakeUserModel } from '@fca-app/models/users/fake-user/fake-user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FakeUsersService {
    constructor(private readonly usersApiService: FcaUsersApiService) {}

    create(firstName: string, lastName: string, image?: string): Observable<FakeUserModel> {
        return this.usersApiService
            .createFakeUser({ firstName, lastName, image })
            .pipe(map(raw => new FakeUserFactory().getModelFromData(new FakeUserMapper().mapData(raw))));
    }

    search(searchTerm: string): Observable<FakeUserModel[]> {
        return this.usersApiService.searchFakeUsers(searchTerm).pipe(
            map(rawData => {
                return rawData.reduce<FakeUserModel[]>((acc, raw) => {
                    acc.push(new FakeUserFactory().getModelFromData(new FakeUserMapper().mapData(raw)));
                    return acc;
                }, []);
            })
        );
    }
}
