import { Component, Input, OnInit } from '@angular/core';
import { EVideoStatus } from '@fca-app/api/fca/arenas/interfaces/response/video-api.response';
import { FightEventType } from '@fca-app/api/fca/web-hooks/interfaces/params/start-stop-event.params';
import { Languages } from '@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/video-link-input/languages';
import { FightEventModel } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import { ELocale } from '@fca-app/shared/enums/locale.enum';

@Component({
    selector: 'modal-start-stop-event',
    templateUrl: './start-stop-event.component.html',
    styleUrls: ['./start-stop-event.component.scss'],
})
export class StartStopEventComponent implements OnInit {
    public listOfOption: { value: string; label: string }[] = Languages;
    public languages: ELocale[] = [ELocale.EN];

    value: string | null = null;
    type: FightEventType | null = null;
    fightEventTypes: string[] = Object.values(FightEventType);
    @Input() inputPlaceholder: string = 'Enter text...';
    @Input() action: 'start' | 'stop';
    @Input() fightEvent: FightEventModel;

    constructor() {}

    onClickType(type: string): void {
        this.type = type as FightEventType;
    }

    ngOnInit(): void {
        // если нет конференции то не можем запустить
        if (!this.fightEvent.conference) {
            this.fightEventTypes = this.fightEventTypes.filter(fet => fet !== FightEventType.CONFERENCE);
        }
        // если нет взвешивания то не можем запустить
        if (!this.fightEvent.weighting) {
            this.fightEventTypes = this.fightEventTypes.filter(fet => fet !== FightEventType.WEIGHTING);
        }
        // можем запустить то что не FINISHED
        if (this.action === 'start') {
            if (this.fightEvent.video!.status === EVideoStatus.FINISHED) {
                this.fightEventTypes = this.fightEventTypes.filter(fet => fet !== FightEventType.FIGHT_EVENT);
            }
            if (this.fightEvent.conference?.video!.status === EVideoStatus.FINISHED) {
                this.fightEventTypes = this.fightEventTypes.filter(fet => fet !== FightEventType.CONFERENCE);
            }
            if (this.fightEvent.weighting?.video!.status === EVideoStatus.FINISHED) {
                this.fightEventTypes = this.fightEventTypes.filter(fet => fet !== FightEventType.WEIGHTING);
            }
        } else if (this.action === 'stop') {
            if (this.fightEvent.video!.status === EVideoStatus.LIVE) {
                this.fightEventTypes = [FightEventType.FIGHT_EVENT];
            }
            if (this.fightEvent.conference?.video!.status === EVideoStatus.LIVE) {
                this.fightEventTypes = [FightEventType.CONFERENCE];
            }
            if (this.fightEvent.weighting?.video!.status === EVideoStatus.LIVE) {
                this.fightEventTypes = [FightEventType.WEIGHTING];
            }
        }
    }
}
