import { Injectable } from '@angular/core';
import { ArenaEventApiResponse } from '@fca-app/api/fca/arenas/interfaces/response/arena-event-api.response';
import { FcaApiServiceBase } from '@fca-app/api/fca/fca-api-service-base.service';
import { CreateFightEventParams } from '@fca-app/api/fca/fight-events/interfaces/params/create-fight-event.params';
import { UpdateFightEventPriceParams } from '@fca-app/api/fca/fight-events/interfaces/params/update-fight-event-price.params';
import { FightEventPriceInfoResponse } from '@fca-app/api/fca/fight-events/interfaces/response/fight-event-price-info.response';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

export interface CreatePpvPriceData {
    currency: string;
    discountPrice: number | null;
    price: number;
    sale: number | null;
}

@Injectable()
export class FcaFightEventsApiService extends FcaApiServiceBase {
    getEventPriceInfo(fightEventId: string): Observable<FightEventPriceInfoResponse> {
        const url = [this.apiUrl, 'fight-events', 'admin', 'price', fightEventId].join('/');

        return this.http
            .get<FightEventPriceInfoResponse>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    setFreeForSubscribers(fightEventId: string, isFree: boolean): Observable<void> {
        const url = [this.apiUrl, 'fight-events', 'admin', 'free-for-subscribers'].join('/');

        return this.http
            .post<void>(url, { fightEventId, freeForSubscribers: isFree }, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    updatePrice(params: UpdateFightEventPriceParams): Observable<void> {
        const url = [this.apiUrl, 'fight-events', 'admin', 'price'].join('/');

        return this.http
            .put<void>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    create(params: CreateFightEventParams): Observable<ArenaEventApiResponse> {
        const url = [this.apiUrl, 'fight-events', 'admin', 'create'].join('/');

        return this.http
            .post<ArenaEventApiResponse>(url, params, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    getFightEvent(id: string): Observable<ArenaEventApiResponse> {
        const url = [this.apiUrl, 'fight-events', id].join('/');
        return this.http
            .get<ArenaEventApiResponse>(url, { headers: this.defaultHeaders })
            .pipe(publishReplay(1), refCount());
    }

    updatePpv(fightEventId: string, prices: CreatePpvPriceData[]): Observable<void> {
        const url = [this.apiUrl, 'fight-events', 'admin', 'update-to-ppv'].join('/');
        return this.http
            .post<void>(
                url,
                {
                    fightEventId,
                    prices,
                },
                { headers: this.defaultHeaders }
            )
            .pipe(publishReplay(1), refCount());
    }
}
