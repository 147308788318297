import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeocodingResponse } from '@fca-app/api/geocoding/interfaces/response/geocoding.response';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

@Injectable()
export class GoogleApiService {
    private readonly apiKey = 'AIzaSyA-utCdQmwAKyTK-YsuIjjWwygk3KPIZ7s';
    private readonly googleEndpoint = 'https://maps.googleapis.com/maps/api/geocode/json';
    constructor(private readonly http: HttpClient) {}

    searchAddress(query: string): Observable<GeocodingResponse> {
        const url = this.googleEndpoint;
        const httpParams: HttpParams = new HttpParams()
            .set('address', query)
            .set('key', this.apiKey)
            .set('language', 'en');

        return this.http
            .get<GeocodingResponse>(url, { params: httpParams })
            .pipe(publishReplay(1), refCount());
    }
}
