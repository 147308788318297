<div>
  <div class="manage-price" [formGroup]="managePriceForm">
    <nz-card nzTitle="Price in coins" style="height: 100%">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input correct price in coins">
          <input type="number" formControlName="price" nz-input placeholder="Price" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input price with discount in coins">
          <input type="number" formControlName="discount" nz-input placeholder="Discount price" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input % of the discount">
          <input type="number" formControlName="sale" nz-input placeholder="Discount in %" />
        </nz-form-control>
      </nz-form-item>
      <button nz-button nzType="primary" [disabled]="!managePriceForm.valid" (click)="onSavePriceClick()">Save</button>
    </nz-card>
  </div>
  <nz-card nzTitle="Additional" style="height: 100%">
    <nz-form-item>
      <nz-form-control>
        <nz-switch [(ngModel)]="isFreeForSubscribers" (click)="onFreeForSubscribersClick()" nzCheckedChildren="Free for subscribers" nzUnCheckedChildren="Paid for subscribers"></nz-switch>
      </nz-form-control>
    </nz-form-item>
  </nz-card>
</div>
