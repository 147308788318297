import { NgModule } from '@angular/core';
import { ChatApiService } from '@fca-app/api/chat/chat-api.service';
import { FcaApiModule } from '@fca-app/api/fca/fca-api.module';
import { GoogleApiService } from '@fca-app/api/geocoding/google-api.service';
import { FcaKicksApiService } from '@fca-app/api/kicks/fca-kicks-api.service';
import { VideoMetricsApiService } from '@fca-app/api/video-metrics/video-metrics-api.service';
import { FcaVideosApiService } from '@fca-app/api/videos/fca-videos-api.service';

@NgModule({
    declarations: [],
    imports: [FcaApiModule],
    providers: [GoogleApiService, VideoMetricsApiService, ChatApiService, FcaKicksApiService, FcaVideosApiService],
})
export class ApiModule {}
