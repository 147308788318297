import { FakeUserApiResponse } from '@fca-app/api/fca/users/interfaces/response/fake-user/fake-user-api.response';
import { FakeUserModelData } from '@fca-app/models/users/fake-user/fake-user.model';

export class FakeUserMapper {
    mapData(raw: FakeUserApiResponse): FakeUserModelData {
        return {
            id: raw.id,
            firstName: raw.firstName,
            lastName: raw.lastName,
            image: raw.image,
        };
    }
}
