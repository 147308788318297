import { AfterViewInit, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserPreviewResponse } from '@fca-app/api/fca/users/interfaces/response/user-preview.response';
import { PaymentsService } from '@fca-app/services/payments.service';
import { UsersService } from '@fca-app/services/users.service';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { NzAutocompleteOptionComponent } from 'ng-zorro-antd/auto-complete';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-payments-coins',
    templateUrl: './payments-coins.component.html',
    styleUrls: ['./payments-coins.component.scss'],
})
export class PaymentsCoinsComponent implements AfterViewInit {
    onSearchUser$ = new Subject<string>();
    foundUsers: UserPreviewResponse[] = [];
    form: FormGroup;
    rewarded: boolean = false;

    constructor(
        private readonly usersService: UsersService,
        private readonly paymentsService: PaymentsService,
        public readonly preloadService: FcaPreloadService,
        private readonly nzNotificationService: NzNotificationService
    ) {
        this.form = new FormGroup({
            coins: new FormControl(0, [Validators.required, Validators.min(1), Validators.max(1000)]),
            selectedUser: new FormControl(null, Validators.required),
            rewardType: new FormControl('bonus', Validators.required),
        });
    }

    ngAfterViewInit(): void {
        this.onSearchUser$
            .pipe(
                untilDestroy(this),
                filter(val => Boolean(val.length)),
                distinctUntilChanged(),
                debounceTime(300),
                tap(() => this.preloadService.preload(true)),
                switchMap(searchTerm => {
                    return this.usersService.previewList(searchTerm, 1, 25).pipe(take(1));
                }),
                finalize(() => this.preloadService.preload(false))
            )
            .subscribe(foundUsers => {
                this.foundUsers = foundUsers;
            });
    }

    onSelectUser(option: NzAutocompleteOptionComponent): void {
        const selectedUser = option.nzValue as UserPreviewResponse;

        this.rewarded = false;
        this.form.patchValue({ selectedUser });
    }

    onRewardClick(): void {
        if (this.form.valid) {
            const user = this.form.value.selectedUser as UserPreviewResponse;
            const amount: number = this.form.value.coins;
            const rewardType: string = this.form.value.rewardType;

            this.paymentsService
                .rewardUser(user.id, amount, rewardType)
                .pipe(untilDestroy(this), take(1))
                .subscribe(() => {
                    this.nzNotificationService
                        .success(`${this.getPreviewName(user)} has received "${amount}" coins`, '', {
                            nzDuration: 2500,
                        })
                        .onClick.pipe(take(1), untilDestroy(this))
                        .subscribe();
                });
        }
    }

    getPreviewName(user: UserPreviewResponse): string {
        const nickname = user.nickname ? `"${user.nickname}"` : '';

        return [user.lastName, nickname, user.firstName].join(' ');
    }
}
