import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserPreviewResponse } from '@fca-app/api/fca/users/interfaces/response/user-preview.response';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { SelectedFighterInSlotCard } from '@fca-app/dashboard/components/events/create/fight-slot/fight-slot.component';
import { EUserRoleName } from '@fca-app/enums/user-role-names.enum';
import { FakeUserModel } from '@fca-app/models/users/fake-user/fake-user.model';
import { FakeUsersService } from '@fca-app/services/fake-users.service';
import { FightersService } from '@fca-app/services/fighters.service';
import { UsersService } from '@fca-app/services/users.service';
import { EImageType } from '@fca-app/shared/enums/image-type.enum';
import { fileToBase64 } from '@fca-app/shared/helpers/file-to-base64.helper';
import { UploadedCropperImage } from '@fca-app/shared/interfaces/uploaded-cropper-image.interface';
import { UntilDestroy, untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzAutocompleteOptionComponent } from 'ng-zorro-antd/auto-complete';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

type SelectFighterType = 'fake' | 'real';

@UntilDestroy()
@Component({
    selector: 'app-events-fight-slot-card',
    templateUrl: './fight-slot-card.component.html',
    styleUrls: ['./fight-slot-card.component.scss'],
})
export class FightSlotCardComponent implements OnInit {
    @Input() position: 'left' | 'right';
    @Input() parentForm: FormGroup;
    @Input() slotNumber: number;

    imageType = EImageType;
    isModalVisible$ = new BehaviorSubject<boolean>(false);
    imageChangedEvent: Event;
    selectType: SelectFighterType = 'real';
    foundUsers: UserPreviewResponse[] = [];
    foundFakeUsers: FakeUserModel[] = [];
    onFightersSearch$ = new Subject<string>();
    onFakeUsersSearch$ = new Subject<string>();
    confirmedFighter?: SelectedFighterInSlotCard;

    constructor(
        private readonly fightersService: FightersService,
        private readonly usersService: UsersService,
        private readonly fakeUsersService: FakeUsersService,
        private readonly appConfigService: AppMainConfigService
    ) {
        this.onFightersSearch$
            .pipe(
                untilDestroy(this),
                filter(search => Boolean(search.length)),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((search: string) => this.usersService.previewList(search, 1, 10, EUserRoleName.FIGHTER))
            )
            .subscribe(users => {
                this.foundUsers = users;
            });

        this.onFakeUsersSearch$
            .pipe(
                untilDestroy(this),
                filter(search => Boolean(search.length)),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap(search => this.fakeUsersService.search(search))
            )
            .subscribe(fakeUsers => {
                this.foundFakeUsers = fakeUsers;
            });
    }

    ngOnInit(): void {
        Promise.resolve().then(() => {
            this.parentForm.addControl(
                this.formKey,
                new FormGroup({
                    id: new FormControl(null),
                    firstName: new FormControl('', [Validators.required]),
                    lastName: new FormControl('', [Validators.required]),
                    image: new FormControl(null),
                    file: new FormControl(null),
                })
            );
        });
    }

    get formKey(): string {
        return `${String(this.slotNumber)}_${this.position}`;
    }

    onSelectTypeChange(checked?: boolean) {
        this.selectType = checked ? 'real' : 'fake';
        this.parentForm.get(this.formKey)!.patchValue({ firstName: '', lastName: '', image: undefined, id: undefined });
        this.confirmedFighter = undefined;
    }

    async onPhotoUpload(uploadedImage: UploadedCropperImage) {
        this.isModalVisible$.next(false);
        this.parentForm.get(this.formKey)!.patchValue({
            image: String(await fileToBase64(uploadedImage.file)),
            file: uploadedImage.file,
        });
        (this.imageChangedEvent.target as HTMLInputElement).value = '';
    }

    onCloseCropper() {
        this.isModalVisible$.next(false);
    }

    edit(e: Event) {
        this.imageChangedEvent = e;
        this.isModalVisible$.next(true);
    }

    onSelectUser(option: NzAutocompleteOptionComponent) {
        const selectedUser = option.nzValue as UserPreviewResponse;

        this.parentForm.get(this.formKey)!.patchValue({
            id: String(selectedUser.id),
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            image: selectedUser.image,
        });
    }

    onSelectFakeUser(option: NzAutocompleteOptionComponent) {
        const selectedFakeUser = option.nzValue as FakeUserModel;

        this.parentForm.get(this.formKey)!.patchValue({
            id: selectedFakeUser.id,
            firstName: selectedFakeUser.firstName,
            lastName: selectedFakeUser.lastName,
            image: selectedFakeUser.image,
        });
    }

    get form(): FormGroup {
        return this.parentForm.get(this.formKey)! as FormGroup;
    }

    get imagePreviewUrl(): string {
        return (
            (Boolean(this.parentForm?.get(this.formKey)) && this.parentForm.get(this.formKey)!.value.image) ||
            this.appConfigService.getConfig().previewImage
        );
    }
}
